@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@layer base {
  h1 {
    @apply text-5xl font-light mb-3;
  }

  h2 {
    @apply text-4xl font-light mb-2;
  }

  h3 {
    @apply text-3xl mb-1;
  }

  h4 {
    @apply text-2xl mb-1;
  }

  h5 {
    @apply text-xl mb-1;
  }

  h6 {
    @apply text-lg font-medium mb-1;
  }

  blockquote {
    @apply border-l-4 border-indigo-300 bg-gray-50 font-serif italic px-6 py-3 my-2;
  }
}
@tailwind components;
@tailwind utilities;
